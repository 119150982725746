import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { Styled, css, useColorMode } from 'theme-ui'
import { Code } from './pageElements/Code'

// There are two special tags that can be replaced too: inlineCode and wrapper. inlineCode is for inline <code> and wrapper is the special element that wraps all of the MDX content.
const components = (isDark: boolean) => ({
  h2: ({ children }: any) => <Styled.h2>{children}</Styled.h2>,
  'p.inlineCode': (props: any) => (
    <code {...props} css={css({ bg: isDark ? '#131313' : '#f4f4f4', color: 'primary' })}></code>
  ),
  pre: ({ children: { props } }: any) => {
    if (props.mdxType === 'code') {
      return (
        <Code
          codeString={props.children.trim()}
          language={props.className && props.className.replace('language-', '')}
          {...props}
        />
      )
    }
  },
  blockquote: ({ children }: any) => (
    <blockquote
      css={css({
        p: '1rem',
        borderRadius: '0.25rem',
        borderLeft: isDark ? '8px solid #9c6ade' : `8px solid #250de9`,
        marginLeft: 0,
        marginRight: 0,
        color: isDark ? '#fff' : '#454f5b',
        bg: isDark ? '#1f0d21' : '#ebebfd',
      } as any)}
    >
      {children}
    </blockquote>
  ),
  img: (props: any) => (
    <span
      css={css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      })}
    >
      <img
        {...props}
        css={css({
          maxWidth: '100%',
          filter: isDark ? 'grayscale(50%)' : 'inherit',
          opacity: isDark ? 0.75 : 1,
        })}
      />
      <span css={css({ fontSize: 1 })}>{props.alt}</span>
    </span>
  ),
})

export default (props: any) => {
  const [currentColorMode] = useColorMode()
  const isDark = currentColorMode === 'dark'
  return (
    <MDXProvider components={() => components(isDark)}>
      <main {...props} />
    </MDXProvider>
  )
}
