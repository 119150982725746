import React from 'react'
import { css, useColorMode } from 'theme-ui'
import { BsBrightnessHighFill, BsMoon } from 'react-icons/bs'

export default function ColorModeToggleButton(props: { injectedCss?: object }) {
  const { injectedCss = {} } = props
  const [currentColorMode, setCurrentColorMode] = useColorMode()

  return (
    <button
      css={css({
        ...{
          cursor: 'pointer',
          px: '1rem',
          py: '0.5rem',
          borderRadius: '0.2rem',
          bg: 'transparent',
          fontSize: [3, 4, 4],
          fontWeight: 500,
          border: 'none',
        },
        ...injectedCss,
      } as any)}
      onClick={() => setCurrentColorMode(currentColorMode === 'default' ? 'dark' : 'default')}
    >
      {currentColorMode === 'default' ? <BsMoon /> : <BsBrightnessHighFill color="white" />}
    </button>
  )
}
