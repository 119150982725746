import React, { useEffect } from 'react'
import 'gitalk/dist/gitalk.css'
import Gitalk from 'gitalk'

const gitalkConfig = {
  clientID: process.env.GATSBY_GITALK_CLIENT_ID || '',
  clientSecret: process.env.GATSBY_GITALK_CLIENT_SECRET || '',
  repo: process.env.GATSBY_GITALK_REPO || '',
  owner: 'hyf152632',
  admin: ['hyf152632'],
  distractionFreeMode: false, // Facebook-like distraction free mode
}

function Comment(props: { id: string; title: string }) {
  const { id, title } = props

  useEffect(() => {
    const gitalk = new Gitalk({ ...gitalkConfig, id, title })
    gitalk.render(`gitalk-container-${id}-${title}`)
  }, [id, title])

  return <div id={`gitalk-container-${id}-${title}`}></div>
}

export default Comment
