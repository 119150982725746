import React from 'react'
import { graphql, PageProps, Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Styled, css, useColorMode } from 'theme-ui'
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs'
import WrapRootElement from '../components/mdxRootWrapper'
import Comment from '../components/comment'
import BannerImageFluid from '../components/pageElements/BannerImageFluid'
import Header from '../components/templateElements/Header'
import TagsGroup from '../components/templateElements/TagsGroup'
import SEO from '../components/seo'

type AdjacentBlogType = {
  title: string
  slug: string
} | null

type Props = {
  pageContext: {
    slug: string
    prev: AdjacentBlogType
    next: AdjacentBlogType
  }
  data: {
    blog: {
      frontmatter: {
        title: string
        date: string
        tags: string[]
        copyright: string | null
        from: string | null
        other: string | null
        banner: any
        overview: string | null
      }
      timeToRead: number
      wordCount: {
        words: number
      }
      excerpt: string
      body: string
    }
  }
}

export default function BlogPost({ data, pageContext }: Props & PageProps) {
  const {
    frontmatter: { title, date, tags, banner, from, copyright, other, overview },
    timeToRead,
    wordCount: { words },
    body,
    excerpt,
  } = data.blog

  const { prev, next } = pageContext

  return (
    <>
      <SEO title={title} description={excerpt} />
      <Header
        label={'📖'}
        backCb={() => {
          history && history.go(-1)
        }}
      />
      <div
        css={css({
          maxWidth: 'blogContainerMaxWidth',
          mx: 'auto',
          mb: '5rem',
          padding: '0.5rem',
        })}
      >
        <BlogInfos
          title={title}
          words={words}
          timeToRead={timeToRead}
          date={date}
          banner={banner}
          from={from}
          copyright={copyright}
          other={other}
          excerpt={excerpt}
          overview={overview}
          tags={tags}
        />
        <Divider label="🏂 正文 👇" />
        <WrapRootElement>
          <MDXRenderer>{body}</MDXRenderer>
        </WrapRootElement>
        <Divider label="👐 The End 🎉" injectedCss={{ mt: '2rem', mb: '5rem' }} />
        <AdjacentBlog prev={prev} next={next} />
        <Divider label="👇  💬" />
        <Comment id={title} title={title} />
      </div>
    </>
  )
}

function AdjacentBlog(props: { prev: AdjacentBlogType; next: AdjacentBlogType }) {
  const { prev, next } = props
  return (
    <Styled.p css={css({ variant: 'layouts.flex-center-between', mt: '2rem' })}>
      <span css={css({ display: 'inline-block', maxWidth: '45%', minWidth: '40%' })}>
        {prev && (
          <span
            css={css({
              maxWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            })}
          >
            <span
              css={css({
                fontSize: [1, 2, 2],
                fontWeight: 'bold',
              })}
            >
              上一篇
            </span>
            <Link
              to={prev.slug}
              css={css({
                variant: 'layouts.flex-center-between',
                color: 'primary',
                ':hover .left': {
                  transform: 'translateX(-5px)',
                },
              })}
            >
              <BsArrowLeftShort
                className="left"
                css={css({ fontSize: 3, transition: 'all .3s' })}
              />
              <span
                css={css({
                  flex: 1,
                  variant: 'utils.text-overflow-ellipsize',
                })}
              >
                &nbsp;{prev.title}
              </span>
            </Link>
          </span>
        )}
      </span>
      <Link to="/">🏠</Link>
      <span css={css({ display: 'inline-block', maxWidth: '45%', minWidth: '40%' })}>
        {next && (
          <span
            css={css({
              maxWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            })}
          >
            <span
              css={css({
                fontSize: [1, 2, 2],
                fontWeight: 'bold',
                textAlign: 'right',
              })}
            >
              下一篇
            </span>
            <Link
              to={next.slug}
              css={css({
                variant: 'layouts.flex-center-between',
                color: 'primary',
                ':hover .right': {
                  transform: 'translateX(5px)',
                },
              })}
            >
              <span
                css={css({
                  flex: 1,
                  variant: 'utils.text-overflow-ellipsize',
                  textAlign: 'right',
                })}
              >
                {next.title}&nbsp;
              </span>
              <BsArrowRightShort
                className="right"
                css={css({ fontSize: 3, transition: 'all .3s' })}
              />
            </Link>
          </span>
        )}
      </span>
    </Styled.p>
  )
}

function BlogInfos(props: {
  title: string
  words?: number
  timeToRead?: number
  date: string
  banner: any
  from: string | null
  copyright: string | null
  other: string | null
  excerpt: string
  tags: string[]
  overview: string | null
}) {
  const {
    title,
    // words,
    // timeToRead,
    date,
    banner,
    from,
    copyright,
    other,
    excerpt,
    tags,
    overview,
  } = props

  const [currentColorMode] = useColorMode()
  const isDark = currentColorMode === 'dark'

  return (
    <>
      <Styled.h1
        css={css({
          position: 'relative',
          pl: '0.5rem',
          '::before': {
            content: '""',
            display: 'inline-block',
            position: 'absolute',
            width: '4px',
            height: '80%',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            bg: 'primary',
            zIndex: 1,
          },
        })}
      >
        {title}
      </Styled.h1>
      <Styled.p
        css={css({
          variant: 'layouts.flex-center-between',
        })}
      >
        {/* <span>
          <span>
            📄&nbsp;<span css={css({ fontSize: 1 })}>{words} words</span>
          </span>
          &nbsp;&nbsp;&nbsp;
          <span>
            🕒&nbsp;<span css={css({ fontSize: 1 })}>{timeToRead} min read</span>
          </span>
        </span> */}
        <span>
          📆&nbsp;<span css={css({ fontSize: 1 })}>{date}</span>
        </span>
      </Styled.p>
      {banner && (
        <BannerImageFluid
          fluid={banner.childImageSharp.fluid}
          alt="Banner Image"
          css={css({
            filter: isDark ? 'grayscale(50%)' : 'inherit',
            opacity: isDark ? 0.75 : 1,
          })}
        />
      )}
      {from && (
        <Styled.p css={css({ fontSize: 1 })}>
          <span>来源：</span>
          <span>{from}</span>
        </Styled.p>
      )}
      {copyright && (
        <Styled.p css={css({ fontSize: 1 })}>
          <span>版权申明：</span>
          <span>{copyright}</span>
        </Styled.p>
      )}
      {other && (
        <Styled.p css={css({ fontSize: 1 })}>
          <span>另外，</span>
          <span>{other}</span>
        </Styled.p>
      )}
      {tags && tags.length > 0 && (
        <Styled.p css={css({ mb: 0 })}>
          <span>🏷&nbsp;</span>
          <TagsGroup tags={tags} asLink={true} />
        </Styled.p>
      )}
      <Styled.p
        css={css({
          fontSize: [1, 2, 2],
        })}
      >
        <span>🖍&nbsp;</span>
        {overview ? <span>{overview}</span> : <span>{excerpt ? excerpt : 'no content...'}</span>}
      </Styled.p>
    </>
  )
}

function Divider(props: { label: string; injectedCss?: {} }) {
  const { label, injectedCss } = props

  return (
    <Styled.p
      css={css({ variant: 'layouts.flex-center-between', my: '2rem', ...injectedCss } as any)}
    >
      <span
        css={css({
          display: 'inline-block',
          flex: 1,
          height: '1px',
          bg: 'primary',
        })}
      ></span>
      <span css={css({ px: '2rem' })}>{label}</span>
      <span
        css={css({
          display: 'inline-block',
          flex: 1,
          height: '1px',
          bg: 'primary',
        })}
      ></span>
    </Styled.p>
  )
}

export const query = graphql`
  query($slug: String!) {
    blog: mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        tags
        copyright
        from
        other
        date(formatString: "YYYY-MM-DD")
        overview
        banner {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      timeToRead
      wordCount {
        words
      }
      excerpt(truncate: true, pruneLength: 80)
      body
    }
  }
`
