import React from 'react'
import { Link } from 'gatsby'
import { css } from 'theme-ui'
import ColorModeToggleButton from './../colorModeToggleButton'

export default function Header(props: { label: string; totalCount?: number; backCb?: () => void }) {
  const { label, totalCount, backCb } = props

  return (
    <div
      css={css({
        boxSizing: 'border-box',
        height: 'headerHeight',
        borderBottom: (theme: any) => `1px solid ${theme.colors.muted}`,
      } as any)}
    >
      <header
        css={css({
          position: 'relative',
          bg: 'background',
          height: '100%',
          variant: 'layouts.flex-center-between',
          maxWidth: 'containerMaxWidth',
          mx: 'auto',
          px: '1rem',
        })}
      >
        {backCb && (
          <button
            css={css({
              cursor: 'pointer',
              fontSize: [3, 4, 5],
              color: 'primary',
              fontWeight: 'bold',
              textDecoration: 'none',
              border: 'none',
              bg: 'transparent',
            })}
            onClick={backCb}
          >
            👈
          </button>
        )}
        {!backCb && (
          <Link
            css={css({
              fontSize: [3, 4, 5],
              color: 'primary',
              fontWeight: 'bold',
              textDecoration: 'none',
            })}
            to="/"
          >
            👈
          </Link>
        )}
        {label && (
          <span
            css={css({
              fontSize: [2, 3, 4],
              color: 'primary',
            })}
          >
            {totalCount && totalCount % 2 === 0 ? '🦄 ' : '🐳 '}
            {label}
            {totalCount && <span>&nbsp;({totalCount})</span>}
          </span>
        )}
        <ColorModeToggleButton />
      </header>
    </div>
  )
}
