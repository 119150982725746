import React from 'react'
import { Link } from 'gatsby'
import { css, useColorMode } from 'theme-ui'

export default function TagsGroup(props: {
  tags: Array<string>
  injectedWrapperCss?: object
  injectedTagCss?: object
  asLink?: boolean
}) {
  const { tags, injectedWrapperCss = {}, injectedTagCss = {}, asLink = false } = props
  const [currentColorMode] = useColorMode()
  const isDark = currentColorMode === 'dark'

  const baseCss = {
    fontSize: 1,
    borderRadius: '0.2rem',
    padding: '0.2rem 0.3rem',
    bg: isDark ? 'background' : '#f4f9fe',
    color: 'primary',
    mr: '0.5rem',
    ...injectedTagCss,
  }

  return (
    <span css={css({ flex: 1, flexWrap: 'wrap', ...injectedWrapperCss } as any)}>
      {tags.map((tag) =>
        asLink ? (
          <Link
            to={`/blog/tag/${tag}`}
            css={css({
              textDecoration: 'none',
              ...baseCss,
            } as any)}
            key={tag}
          >
            {tag}
          </Link>
        ) : (
          <span
            css={css({
              ...baseCss,
            } as any)}
            key={tag}
          >
            {tag}
          </span>
        )
      )}
    </span>
  )
}
